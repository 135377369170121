<template>
  <div class="flex flex-col justify-center items-center w-full h-screen">
    <div class="sm:px-2">
      <div class="mb-14 sm:w-72">
        <h2 class="font-main font-bold text-3xl md:text-xl lg:text-xl">
          Saisissez le nouveau mot de passe
        </h2>
        <p
          class="font-main font-normal text-base md:text-sm lg:text-sm text-promy-gray-250"
        >
          Changer votre mot de passe
        </p>
      </div>
      <div class="reset-form">
        <ValidationObserver
          tag="form"
          v-slot="{ invalid }"
          @submit.prevent="submitResetForm"
        >
          <pro-input
            label="Email"
            :placeholder="'Email'"
            :rules="'required|email'"
            v-model="form.email"
            type="text"
            class="mb-7"
            disabled
          />
          <pro-input
            label="Nouveau mot de passe"
            :placeholder="'Nouveau mot de passe'"
            :rules="'required'"
            :with_rule="true"
            v-model="form.password"
            :type="password_input_type"
            class="mb-7"
            @passwordInputIsValidated="(value) => (password_is_valid = value)"
            withShowPasswordIcon
            @updateType="(type) => (password_input_type = type)"
          />
          <pro-input
            label="Confirmez le mot de passe"
            :placeholder="'Confirmez le mot de passe'"
            :rules="`required|confirmedBy:${form.password}`"
            :with_rule="true"
            v-model="form.password_confirmation"
            :type="password_confirmation_input_type"
            @passwordInputIsValidated="
              (value) => (password_confirmation_is_valid = value)
            "
            withShowPasswordIcon
            @updateType="(type) => (password_confirmation_input_type = type)"
          />
          <div class="flex flex-col mt-5 space-y-4">
            <pro-button
              type="submit"
              :disabled="
                invalid && !password_is_valid && !password_confirmation_is_valid
              "
              class="rounded-lg"
              btn="primary"
            >
              <span>Validez</span>
            </pro-button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
        token: this.$route.query.token || null,
      },
      password_is_valid: false,
      password_confirmation_is_valid: false,
      password_input_type: 'password',
      password_confirmation_input_type: 'password',
    }
  },
  mounted() {
    if (this.form.token === null) {
      this.navigateLogin()
    }
  },
  methods: {
    navigateLogin() {
      this.$router.push({ name: 'Login' })
    },
    async submitResetForm() {
      try {
        if (this.form.password !== this.form.password_confirmation) {
          this.toast(
            'Réinitialisation du mot de passe',
            'Les mots de passe entrés ne sont pas identiques',
            'warning',
          )
          return
        }
        const response = await this.$http.post(
          'grand-publics/password/reset',
          this.form,
        )
        this.toast(
          'Réinitialisation du mot de passe',
          response.data.message,
          'success',
        )
        this.$router.push({ name: 'SuccessReset' })
      } catch (error) {
        this.toast(
          'Réinitialisation du mot de passe',
          error.response.data.error,
          'error',
        )
      }
    },
  },
}
</script>

<style scoped>
.reset-form {
  width: 420px;
}
@media (max-width: 768px) {
  .reset-form {
    width: 350px;
  }
}
@media (max-width: 639px) {
  .reset-form {
    width: 300px;
    margin: 0 auto;
  }
}
</style>
